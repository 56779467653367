import styled, { css } from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';
import { padding } from '../../../styles/atoms/spacing';
import shadow from '../../../styles/atoms/shadows';

import { Cta } from '../../../molecules/CallToAction/CallToAction.styles';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 16px;
  ${padding(32)}
  background-color: ${colors.base.white};
  border-radius: 8px;
  ${shadow('none')}
  overflow: hidden;

  &:hover {
    ${shadow('xl')}
  }

  ${Cta} {
    align-self: flex-end;
    z-index: 3;
  }
`;

export const ImageContainer = styled.div`
  background-color: ${props => props.color};
  ${padding(15)}
  border-radius: 12px;

  img {
    width: 26px;
    height: 26px;
  }
`;

export const Heading = styled.h3`
  ${font('display', 'sm', '700')}
  color: ${colors.gray[900]};
  width: 100%;
  z-index: 3;

  ${atMinWidth.md`
    ${font('display', 'md', '700')}
  `}
`;

export const Subhead = styled.div`
  ${font('text', 'xs', '400')}
  color: ${colors.gray[700]};
  margin-bottom: 20px;
  z-index: 3;

  ${atMinWidth.sm`
    margin-bottom: 36px
  `}

  ${atMinWidth.md`
    ${font('text', 'sm', '400')}
    margin-bottom: 84px;
  `}
`;

const hexShown = css`
  bottom: -115px;
  left: -70px;
  opacity: 1;
  transform: scaleX(-1) rotate(10deg);

  ${atMinWidth.md`
    bottom: -70px;
  `}
`;

export const HexContainer = styled.div`
  position: absolute;
  ${hexShown}
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  z-index: 1;

  ${atMinWidth.lg`
    bottom: -100px;
    left: -100px;
    opacity: 0;
    transform: scaleX(-1) rotate(-10deg);
  `}

  svg {
    width: 300px;
    height: 220px;
  }

  ${Wrapper}:hover & {
    ${hexShown}
  }
`;
